import { useTranslation } from 'react-i18next';
import cddGlobal from '../resources/img/projects_showcase/web_img/cdd-global-services.svg';
import wellbein from '../resources/img/projects_showcase/web_img/wellbeinn-xpolar.svg';
import floridaElitePool from '../resources/img/projects_showcase/web_img/florida-elite-pool-services.png';
import mySkinByAdri from '../resources/img/projects_showcase/web_img/my-skin-by-adri-services.png';
import alphaServicesUsa from '../resources/img/projects_showcase/web_img/alpha-services-usa.png';
import ecotecaApp from '../resources/img/projects_showcase/mobile_img/ecoteca-app.png';

export const useProjectData = () => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      title: t('projects.cddGlobal.title'),
      description: t('projects.cddGlobal.description'),
      imageUrl: cddGlobal,
      link: 'https://cddglobalser.com/',
      type: 'web',
    },
    {
      id: 2,
      title: t('projects.wellbeinXpolar.title'),
      description: t('projects.wellbeinXpolar.description'),
      imageUrl: wellbein,
      link: 'https://wellbeinn.com/',
      type: 'web',
    },
    {
      id: 3,
      title: t('projects.floridaElitePool.title'),
      description: t('projects.floridaElitePool.description'),
      imageUrl: floridaElitePool,
      link: 'https://floridaelitepool.com/',
      type: 'web',
    },
    {
      id: 4,
      title: t('projects.mySkinByAdri.title'),
      description: t('projects.mySkinByAdri.description'),
      imageUrl: mySkinByAdri,
      link: 'https://myskinbyadri.com/',
      type: 'web',
    },
    {
      id: 5,
      title: t('projects.alphaServicesUsa.title'),
      description: t('projects.alphaServicesUsa.description'),
      imageUrl: alphaServicesUsa,
      link: 'https://alphaserviceusa.com/',
      type: 'web',
    },
    {
      id: 6,
      title: t('projects.ecotecaApp.title'),
      description: t('projects.ecotecaApp.description'),
      imageUrl: ecotecaApp,
      link: 'https://play.google.com/store/apps/details?id=com.pocusapp.ecoteca&hl=es_CL',
      type: 'mobile',
    },
  ];
};
