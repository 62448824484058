import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import avatar from '../resources/img/avatars/avatar_matias_ignacio_perfil_profesional.svg';
import { useTranslation } from 'react-i18next';
import '../styles/ContactFormModal.css';

interface ContactModalProps {
  show: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();
  const [isAvatarAnimated, setIsAvatarAnimated] = useState(false);
  const [message, setMessage] = useState(''); // Estado para manejar mensajes

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const form = event.currentTarget;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(new FormData(form) as any).toString(),
    })
      .then((response) => {
        if (response.ok) {
          setMessage("Formulario enviado con éxito."); // Mensaje de éxito
          onClose(); // Cerrar el modal
          form.reset(); // Limpiar el formulario
        } else {
          setMessage("Error al enviar el formulario."); // Mensaje de error
        }
      })
      .catch((error) => {
        console.error("Error al enviar el formulario:", error);
        setMessage("Hubo un problema al enviar el formulario."); // Mensaje de error
      });
  };

  useEffect(() => {
    if (show) {
      setIsAvatarAnimated(true);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="modal-title text-center">
          {t('projectDescription')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="avatar-container text-center mb-3">
            <img
              src={avatar}
              alt="Perfil profesional de Matías Ignacio"
              className={`avatar-img img-fluid ${isAvatarAnimated ? 'animated-avatar' : ''}`}
            />
          </div>

          <hr className="divider" />

          {message && <div className="alert alert-info">{message}</div>} {/* Mensaje de estado */}

          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <p className="anti-spam" style={{ display: 'none' }}>
              <label>{t('antiSpamLabel')} <input name="bot-field" /></label>
            </p>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="nombre" className="form-label">{t('fullName')}</label>
                  <input type="text" className="form-control" id="nombre" name="nombre" placeholder={t('fullNamePlaceholder')} required />
                </div>
                <div className="col">
                  <label htmlFor="correo" className="form-label">{t('email')}</label>
                  <input type="email" className="form-control" id="correo" name="correo" placeholder={t('emailPlaceholder')} required />
                </div>
              </div>
            </div>

            <hr className="divider" />

            <div className="form-group">
              <label htmlFor="mensaje" className="form-label">{t('projectDescriptionField')}</label>
              <textarea className="form-control" id="mensaje" name="mensaje" rows={4} placeholder={t('projectDescriptionPlaceholder')} required></textarea>
            </div>

            <div className="text-center mt-3">
              <button type="submit" className="btn-submit">{t('submitInfo')}</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;