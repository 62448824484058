import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../resources/img/favicon/centaurcode-favicon.svg';
import usFlag from '../resources/img/flags/en_flag.svg';
import esFlag from '../resources/img/flags/es_flag.svg'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../styles/Header.css';

interface HeaderProps {
    onOpenModal: () => void;
}

const Header: React.FC<HeaderProps> = ({ onOpenModal }) => {
    const { t, i18n } = useTranslation();

    // Cambia la bandera según el idioma a seleccionar
    const flag = i18n.language === 'es' ? usFlag : esFlag;

    const changeLanguage = () => {
        const newLang = i18n.language === 'es' ? 'en' : 'es';
        i18n.changeLanguage(newLang);
    };

    useEffect(() => {
        const browserLang = navigator.language;
        if (browserLang.includes('es') && i18n.language !== 'es') {
            i18n.changeLanguage('es');
        } else if (browserLang.includes('en') && i18n.language !== 'en') {
            i18n.changeLanguage('en');
        }
    }, [i18n]);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light header-custom">
            <a className="header__brand" href="./index.html">
                <img
                    src={logo}
                    className="header__logo"
                    alt="CentaurCode Favicon"
                />
            </a>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end text-center" id="navbarNav">
                <ul className="navbar-nav align-items-center">
                    <li className="nav-item">
                        <a
                            className="nav-link header__explore-projects"
                            href="https://github.com/Matignaciom"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('exploreProjects')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <span
                            className="nav-link text-center"
                            onClick={changeLanguage} 
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="d-flex align-items-center justify-content-center mr-2">
                                <span className="mr-2 header__view-in-english">
                                    {t(i18n.language === 'es' ? 'viewInEnglish' : 'viewInSpanish')}
                                </span>
                                <img
                                    src={flag} 
                                    alt={i18n.language === 'es' ? 'US Flag' : 'Spanish Flag'}
                                    style={{ width: '30px', height: '30px' }}
                                />
                            </div>
                        </span>
                    </li>
                    <li className="nav-item">
                        <button
                            className="nav-link btn btn-primary header__contact-button"
                            onClick={onOpenModal}
                        >
                            {t('contactMe')}
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;
