import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ContactSection.css';

interface ContactSectionProps {
  onOpenModal: () => void; // Prop para abrir el modal
}

const ContactSection: React.FC<ContactSectionProps> = ({ onOpenModal }) => {
  const { t } = useTranslation(); // Inicializar el hook

  return (
    <div className="container"> {/* Contenedor de Bootstrap */}
      <section className="contact-section">
        <div className="card contact-card p-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-12 text-center mb-4">
                <h2 className="contact-title">{t('wantToTalk')}</h2> {/* Traducción */}
              </div>
              <div className="col-md-12 text-center mb-4">
                <p className="contact-description">
                  {t('collaborateTogether')}
                  <br />
                  {t('letDiscussIdeas')}
                </p>
              </div>
              <div className="col-md-12 text-center">
                <button
                  className="btn btn-primary btn-outline rounded-pill btn-lg font-weight-normal contact-button"
                  onClick={onOpenModal} // Llama a onOpenModal
                >
                  <span className="fa fa-hand-horns mr-2"></span>
                  <strong>{t('scheduleMeeting')}</strong> {/* Traducción */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactSection;
