import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../resources/img/favicon/centaurcode-favicon.svg';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <a href="./index.html">
        <img
          className="footer__logo mb-1"
          src={logo}
          alt="Logo"
        />
      </a>
      <p className="footer__text">
        {t('growingAndImproving')} <br /> {t('inSoftwareDevelopment')}
      </p>

      <div className="container mt-4">
        <div className="footer__social-icons">
          <a
            href="https://www.linkedin.com/in/matiasigparedes/"
            target="_blank"
            rel="noreferrer"
            className="footer__social-icon"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a
            href="https://github.com/Matignaciom"
            target="_blank"
            rel="noreferrer"
            className="footer__social-icon"
          >
            <i className="fab fa-github-alt"></i>
          </a>
          <a
            href="https://www.instagram.com/matlvgvs/"
            target="_blank"
            rel="noreferrer"
            className="footer__social-icon"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>

      <p className="footer__text">
        {t('createdBy')} <br /> <strong>&copy; 2024</strong> CentaurCode.
      </p>
    </footer>
  );
};

export default Footer;
