import React from 'react';
import { useTranslation } from 'react-i18next';
import avatar from '../resources/img/avatars/avatar_matias_ignacio_perfil_profesional.svg';
import devices from '../resources/img/devices/devices.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../styles/ProfileIntro.css';

const ProfileIntro: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="profile-intro__container">
      {/* Sección 1 */}
      <section className="profile-intro__text-center pb-4 mb-4">
        <h1 className="profile-intro__heading">{t('title')}</h1>
        <p className="profile-intro__paragraph">
          {t('description')} <strong style={{ color: '#6E07F3' }}>{t('webMobileDeveloper')}</strong> <span>{t('atYourService')}</span>.
        </p>
        <div className="mt-4">
          <img
            src={avatar}
            alt="Avatar Matías Ignacio Perfil Profesional"
            style={{ width: '195px', textAlign: 'center' }}
          />
        </div>
      </section>

      {/* Sección 2 */}
      <section>
        <div className="profile-intro__text-center">
          <img
            src={devices}
            alt="devices large banner"
            className="profile-intro__img-fluid"
            style={{ width: '650px' }}
          />
        </div>
        <br />
        <div className="container"> {/* Contenedor de Bootstrap */}
          <div className="profile-intro__bg-purple text-center">
            <h2 className="profile-intro__greeting-heading">
              {t('greetingHeading')}
            </h2>
            <p className="profile-intro__experience-paragraph">
              {t('experienceParagraph')}
            </p>
            <p className="profile-intro__challenges-paragraph">
              {t('challengesParagraph')}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfileIntro;
