import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const getBrowserLanguage = () => {
  const language = navigator.language;
  return language.includes('es') ? 'es' : 'en';
};

i18n
  .use(initReactI18next)
  .init({
    lng: getBrowserLanguage(),
    resources: {
      es: {
        translation: {
          "title": "Ingeniero de Software, Programador Web | Móvil",
          "description": "Programador de Aplicaciones FullStack,",
          "exploreProjects": "Explorar Proyectos",
          "viewInEnglish": "Ver en inglés",
          "viewInSpanish": "Ver en español",
          "contactMe": "¡Conéctate conmigo!",
          "growingAndImproving": "Creciendo y mejorando cada día",
          "inSoftwareDevelopment": "en el desarrollo de software",
          "createdBy": "Creada por Matías Ignacio",
          "wantToTalk": "¿Te gustaría conversar?",
          "collaborateTogether": "¿Te gustaría colaborar juntos?",
          "letDiscussIdeas": "Hagamos una reunión para discutir tus ideas.",
          "scheduleMeeting": "Acordar Reunión",
          "projectDescription": "¡Cuéntanos sobre tu Proyecto!",
          "fullName": "Nombre Completo",
          "fullNamePlaceholder": "Ingresa tu nombre completo",
          "email": "Correo Electrónico",
          "emailPlaceholder": "Tu correo electrónico",
          "projectDescriptionField": "Descripción del Proyecto",
          "projectDescriptionPlaceholder": "Danos una breve descripción de tu proyecto",
          "submitInfo": "Enviar Información",
          "antiSpamLabel": "Por favor, ignora esto si eres humano: ",
          "clientTestimonials": { 
            "title": "Testimonios de Clientes",
            "subtitle": "Lo que nuestros clientes dicen sobre nosotros...",
            "gennaro": {
              "text": "Colaborar con CentaurCode ha sido una experiencia transformadora. Su meticulosa atención al detalle y la implementación de soluciones innovadoras no solo cumplieron, sino que superaron nuestras expectativas.",
              "position": "Propietario de Florida Elite Pool"
            },
            "adriana": {
              "text": "Desde que implementamos las soluciones de CentaurCode en nuestra tienda en línea, hemos visto un cambio significativo. Las soluciones personalizadas proporcionadas han transformado nuestro sitio web en una experiencia de compra en línea excepcionalmente atractiva.",
              "position": "Propietaria de My Skin by Adri"
            }
          },
          "greetingHeading": "¡Hola! Soy Matías, un placer conocerte.", 
          "experienceParagraph": "Soy un apasionado desarrollador web y móvil con una sólida experiencia en crear aplicaciones. Me encanta explorar tecnologías innovadoras y enfoques creativos para ofrecer soluciones efectivas que mejoren la experiencia del usuario.", // Nueva clave
          "challengesParagraph": "A lo largo de mi carrera, he superado diversos desafíos que han fortalecido mis habilidades en el diseño e implementación de sistemas robustos. Estoy siempre entusiasmado por aprender nuevas herramientas y técnicas que impulsan la innovación y mejoran mis proyectos.", // Nueva clave
          "webMobileDeveloper": "Desarrollador Web y Móvil", 
          "atYourService": "a tu servicio", 
          "recentProjects": "Proyectos Recientes", 
          "webProjects": "Proyectos Web", 
          "mobileProjects": "Proyectos Móviles", 
          "viewProject": "Ver Proyecto", 
          "exploreMoreProjects": "Explorar Más Proyectos",

          "projects": {
            "cddGlobal": {
              "title": "CDD Global Services",
              "description": "Seguros personalizados a medida."
            },
            "wellbeinXpolar": {
              "title": "Wellbeinn Xpolar",
              "description": "Prepárate y recupérate con Xpolar."
            },
            "floridaElitePool": {
              "title": "Florida Elite Pool",
              "description": "Calidad y Creatividad."
            },
            "mySkinByAdri": {
              "title": "My skin by adri",
              "description": "Cuerpo, alopecia, botox/rellenos."
            },
            "alphaServicesUsa": {
              "title": "Alpha Services USA",
              "description": "Fiabilidad y eficacia."
            },
            "ecotecaApp": {
              "title": "Ecoteca App",
              "description": "Explora la medicina con Ecoteca."
            }
          },


          "webDeveloper": {
            "title": "Desarrollador Web",
            "skills": "JavaScript | React.js | Git | SOLID | Jest | WordPress | Firebase | Supabase",
            "description": "Experto en frontend y BaaS, impulso proyectos con mejoras notables en la interfaz y eficiencia en la nube. Potencia tu proyecto con mis habilidades sólidas y enfoque proactivo."
          },
          "gameDeveloper": {
            "title": "Desarrollador de Juegos",
            "skills": "C# | Desarrollo 3D/2D | Físicas | UI | Animaciones | Integraciones | Git",
            "description": "Experto en C# y Unity, transformo ideas en juegos cautivadores con implementaciones eficientes y diseño inmersivo. Potencia tu proyecto con mi enfoque proactivo y experiencia integral."
          },
          "mobileApps": {
            "title": "Aplicaciones Móviles",
            "skills": "Flutter | FlutterFlow | Firebase | Supabase | UI/UX | Cross-Platform | APIs",
            "description": "Desarrollador en Flutter y FlutterFlow, transformo ideas en aplicaciones cautivadoras con diseño intuitivo y eficiencia. Potencia tu proyecto con mi enfoque proactivo."
          }
        },
      },
      en: {
        translation: {
          "title": "Software Engineer, Web | Mobile Developer",
          "description": "FullStack Application Developer,",
          "exploreProjects": "Explore Projects",
          "viewInEnglish": "View in English",
          "viewInSpanish": "View in Spanish",
          "contactMe": "¡Connect with me!",
          "growingAndImproving": "Growing and improving every day",
          "inSoftwareDevelopment": "in software development",
          "createdBy": "Created by Matías Ignacio",
          "wantToTalk": "Would you like to talk?",
          "collaborateTogether": "Would you like to collaborate together?",
          "letDiscussIdeas": "Let's have a meeting to discuss your ideas.",
          "scheduleMeeting": "Schedule Meeting",
          "projectDescription": "Tell us about your Project!",
          "fullName": "Full Name",
          "fullNamePlaceholder": "Enter your full name",
          "email": "Email",
          "emailPlaceholder": "Your email address",
          "projectDescriptionField": "Project Description",
          "projectDescriptionPlaceholder": "Give us a brief description of your project",
          "submitInfo": "Submit Information",
          "antiSpamLabel": "Please ignore this if you are human: ",
          "clientTestimonials": { 
            "title": "Client Testimonials",
            "subtitle": "What our clients say about us...",
            "gennaro": {
              "text": "Collaborating with CentaurCode has been a transformative experience. Their meticulous attention to detail and implementation of innovative solutions not only met but exceeded our expectations.",
              "position": "Owner of Florida Elite Pool"
            },
            "adriana": {
              "text": "Since we implemented CentaurCode's solutions in our online store, we've seen a significant change. The custom solutions provided have transformed our website into an exceptionally engaging online shopping experience.",
              "position": "Owner of My Skin by Adri"
            }
          },
          "greetingHeading": "Hello! I'm Matías, nice to meet you.", 
          "experienceParagraph": "I'm a passionate web and mobile developer with solid experience in creating applications. I love exploring innovative technologies and creative approaches to deliver effective solutions that enhance the user experience.", // Nueva clave
          "challengesParagraph": "Throughout my career, I've overcome various challenges that have strengthened my skills in designing and implementing robust systems. I'm always excited to learn new tools and techniques that drive innovation and improve my projects.", // Nueva clave
          "webMobileDeveloper": "Web and Mobile Developer", 
          "atYourService": "at your service", 
          "recentProjects": "Recent Projects", 
          "webProjects": "Web Projects", 
          "mobileProjects": "Mobile Projects", 
          "viewProject": "View Project", 
          "exploreMoreProjects": "Explore More Projects",

          "projects": {
            "cddGlobal": {
              "title": "CDD Global Services",
              "description": "Customized insurance services."
            },
            "wellbeinXpolar": {
              "title": "Wellbeinn Xpolar",
              "description": "Prepare and recover with Xpolar."
            },
            "floridaElitePool": {
              "title": "Florida Elite Pool",
              "description": "Quality and creativity."
            },
            "mySkinByAdri": {
              "title": "My skin by adri",
              "description": "Body, alopecia, botox/fillers."
            },
            "alphaServicesUsa": {
              "title": "Alpha Services USA",
              "description": "Reliability and efficiency."
            },
            "ecotecaApp": {
              "title": "Ecoteca App",
              "description": "Explore medicine with Ecoteca."
            }
          },

          "webDeveloper": {
            "title": "Web Developer",
            "skills": "JavaScript | React.js | Git | SOLID | Jest | WordPress | Firebase | Supabase",
            "description": "Frontend and BaaS expert, I drive projects with notable improvements in interface and cloud efficiency. Boost your project with my solid skills and proactive approach."
          },
          "gameDeveloper": {
            "title": "Game Developer",
            "skills": "C# | 3D/2D Development | Physics | UI | Animations | Integrations | Git",
            "description": "Expert in C# and Unity, I transform ideas into captivating games with efficient implementations and immersive design. Enhance your project with my proactive approach and comprehensive experience."
          },
          "mobileApps": {
            "title": "Mobile Apps",
            "skills": "Flutter | FlutterFlow | Firebase | Supabase | UI/UX | Cross-Platform | APIs",
            "description": "Flutter and FlutterFlow developer, I transform ideas into captivating applications with intuitive design and efficiency. Boost your project with my proactive approach."
          }
        },
      },
    },
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;