import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import ProfileIntro from './components/ProfileIntro';
import ServiceCards from './components/ServiceCards';
import ProjectsShowcase from './components/ProjectsShowcase';
import ClientTestimonials from './components/ClientTestimonials';
import ContactModal from './components/ContactModal';
import ContactSection from './components/ContactSection';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import './data/i18n';

function App() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <div className="App">
      {/* Pasa la función handleOpenModal al Header */}
      <Header onOpenModal={handleOpenModal} />
      <div className="main-content">
        <ProfileIntro />
        <ServiceCards />
        <ProjectsShowcase />
        <ClientTestimonials />
        {/* Agrega el ContactSection que abre el modal */}
        <ContactSection onOpenModal={handleOpenModal} />
      </div>
      <Footer />

      {/* Agrega el modal de contacto */}
      <ContactModal show={modalVisible} onClose={handleCloseModal} />
    </div>
  );
}

export default App;
