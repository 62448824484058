import React, { useState, useMemo } from 'react';
import { useProjectData } from '../data/projectsData'; 
import { useTranslation } from 'react-i18next'; 
import '../styles/ProjectsShowcase.css';

const ProjectsShowcase: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'web' | 'mobile'>('web');
  const { t } = useTranslation(); 
  const projects = useProjectData(); 

  // Filtra los proyectos según el tab activo
  const filteredProjects = useMemo(
    () => projects.filter(project => project.type === activeTab),
    [projects, activeTab]
  );

  return (
    <div className="projects-showcase container text-center"> 
      <h2 className="showcase-title">{t('recentProjects')}</h2>
      <div className="showcase-tab-bar text-center">
        <button
          className={`showcase-tab-button ${activeTab === 'web' ? 'active' : ''}`}
          onClick={() => setActiveTab('web')}
        >
          {t('webProjects')}
        </button>
        <button
          className={`showcase-tab-button ${activeTab === 'mobile' ? 'active' : ''}`}
          onClick={() => setActiveTab('mobile')}
        >
          {t('mobileProjects')}
        </button>
      </div>

      <div className="showcase-projects-list row justify-content-center"> 
        {filteredProjects.map(project => (
          <div key={project.id} className="col-md-4 col-sm-6 showcase-project-card"> 
            <img src={project.imageUrl} alt={project.title} className="img-fluid showcase-project-image" />
            <h3 className="showcase-project-title">{project.title}</h3>
            <p className="showcase-project-description">{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer" className="showcase-project-button">
              {t('viewProject')}
            </a>
          </div>
        ))}
      </div>

      {/* Botón de "Explorar Más Proyectos" debajo de la lista de proyectos */}
      <div className="explore-more-projects">
        <a href="https://github.com/Matignaciom" target='_blank' rel='noreferrer' className="explore-button">
          {t('exploreMoreProjects')}
        </a>
      </div>
    </div>
  );
};

export default ProjectsShowcase;
