import React from 'react';
import { useTranslation } from 'react-i18next';
import webDevIcon from '../resources/img/services/web-dev-icon.svg';
import gameDevIcon from '../resources/img/services/game-dev-icon.svg';
import mobileDevIcon from '../resources/img/services/mobile-dev-icon.svg';
import '../styles/ServiceCards.css';

interface ServiceCardProps {
  title: string;
  skills: string;
  description: string;
  imageSrc: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, skills, description, imageSrc }) => {
  return (
    <div className="card service-card">
      <div className="card-body">
        <img src={imageSrc} alt={`${title} icon`} className="service-icon mb-3" />
        <h5 className="card-title">{title}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{skills}</h6>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
};

const ServiceCards: React.FC = () => {
  const { t } = useTranslation(); 

  return (
    <div className="container text-center mt-5">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 mb-4">
          <ServiceCard
            title={t('webDeveloper.title')}
            skills={t('webDeveloper.skills')}
            description={t('webDeveloper.description')}
            imageSrc={webDevIcon}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12 mb-4">
          <ServiceCard
            title={t('gameDeveloper.title')}
            skills={t('gameDeveloper.skills')}
            description={t('gameDeveloper.description')}
            imageSrc={gameDevIcon}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-12 mb-4">
          <ServiceCard
            title={t('mobileApps.title')}
            skills={t('mobileApps.skills')}
            description={t('mobileApps.description')}
            imageSrc={mobileDevIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceCards;
