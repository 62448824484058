import React from 'react';
import { useTranslation } from 'react-i18next';
import floridaElitePoolLogo from '../resources/img/testimonios_template/testimonio1.png';
import mySkinByAdriLogo from '../resources/img/testimonios_template/testimonio2.png';

import '../styles/ClientTestimonials.css';

const ClientTestimonials: React.FC = () => {
  const { t } = useTranslation(); // Usar el hook useTranslation

  const testimonials = [
    {
      name: "Gennaro C.",
      text: t('clientTestimonials.gennaro.text'), // Usar traducción
      position: t('clientTestimonials.gennaro.position'), // Usar traducción
      image: floridaElitePoolLogo
    },
    {
      name: "Adriana N.",
      text: t('clientTestimonials.adriana.text'), // Usar traducción
      position: t('clientTestimonials.adriana.position'), // Usar traducción
      image: mySkinByAdriLogo
    },
  ];

  return (
    <section className="my-5">
      <h2 className="text-center mb-4">{t('clientTestimonials.title')}</h2>
      <p className="text-center mb-4">{t('clientTestimonials.subtitle')}</p>
      <div className="container">
        <div className="row justify-content-center">
          {testimonials.map((testimonial, index) => (
            <div className="col-md-6 col-lg-4 mb-4" key={index}>
              <div className="testimonial-card card shadow-sm">
                <div className="card-body text-center">
                  <img
                    src={testimonial.image}
                    alt={`${testimonial.name}'s Avatar`}
                    className="rounded-circle mb-3"
                    style={{ width: '100px', height: '100px' }}
                  />
                  <h5 className="font-weight-bold">{testimonial.name}</h5>
                  <p className="text-muted">{testimonial.position}</p>
                  <p className="testimonial-text">{testimonial.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonials;
